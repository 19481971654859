import * as React from 'react'
import loadable from '@loadable/component'

const StandardPage = loadable(() => import('/src/templates/StandardPage'))

const Page = ({ postInfo }) => {
    const post = {
        title: 'Terms and Conditions for Lifestyle Voucher Offer',
        content: `<div class="row" style="margin-top: 20px;" ><div class="col-lg-10 offset-lg-1"><ol>
<li>These terms and conditions are promoted by Protect Line Limited trading as Compare The Quote, whose registered office is at Bourne Gate, 25 Bourne Valley Road, Poole, Dorset, BH12 1DY ("Promoter").</li>
<li>To be eligible for a Lifestyle voucher, you must be a UK resident aged 18 or over (excluding employees of the Promoter, third parties involved in the Promotion, and their families).</li>
<li>The offer is valid for those who purchase a life insurance and/or critical illness cover policy online via Protect Line from 10<sup>th</sup> September 2024 to 31st December 2025. The Promoter reserves the right to extend this offer, updating these terms and conditions accordingly.</li>
<li>To receive a Lifestyle voucher, you must: a) Start a life insurance and/or critical illness cover policy via a specified advert, email, or text message b) Get a quote and have a policy accepted c) Keep the policy active and make all payments in full for a minimum of six months</li>
<li>The value of the Lifestyle voucher is tiered based on the monthly premium of your policy, as follows:</li>

<table class=" table table-striped benifits-table" width="0"><thead><tr><td width="277">
<p><strong>Life Insurance monthly premium cost</strong></p>
</td><td width="277">
<p><strong>Voucher Value</strong></p>
</td></tr></thead><tbody><tr><td width="277">
<p>£0.00 - £10.00</p>
</td><td width="277">
<p>£0.00</p>
</td></tr><tr><td width="277">
<p>£10.01 - £20.00</p>
</td><td width="277">
<p>£30.00</p>
</td></tr><tr><td width="277">
<p>£20.01 - £30.00</p>
</td><td width="277">
<p>£60.00</p>
</td></tr><tr><td width="277">
<p>£30.01 - £40.00</p>
</td><td width="277">
<p>£90.00</p>
</td></tr><tr><td width="277">
<p>£40.01 - £50.00</p>
</td><td width="277">
<p>£120.00</p>
</td></tr><tr><td width="277">
<p>£50.01 - £60.00</p>
</td><td width="277">
<p>£150.00</p>
</td></tr><tr><td width="277">
<p>£60.01 -£70.00</p>
</td><td width="277">
<p>£180.00</p>
</td></tr><tr><td width="277">
<p>£70.01 -£80.00</p>
</td><td width="277">
<p>£210.00</p>
</td></tr><tr><td width="277">
<p>£80.01 -£90.00</p>
</td><td width="277">
<p>£240.00</p>
</td></tr><tr><td width="277">
<p>£90.01 -£99.99</p>
</td><td width="277">
<p>£270.00</p>
</td></tr><tr><td width="277">
<p>£100.00 +</p>
</td><td width="277">
<p>£300.00</p>
</td></tr></tbody></table>

<li>You will be entitled to claim the Lifestyle voucher once following payment of the sixth monthly premium. Each eligible customer will receive an email within 45 days of the sixth monthly premium payment from the Promoter, containing details on how to claim your Lifestyle voucher.</li>
<li>All Lifestyle voucher claims must be made within 6 months from the date of the email from the Promoter. If you do not claim within this period, you will no longer be eligible to receive the Lifestyle voucher.</li>
<li>The Promoter will send the voucher claim email to the email address provided by the customer when the policy was taken out. It is the customer's responsibility to ensure that the email address provided is correct and remains active. The customer should regularly check their email inbox, including spam or junk folders, for communications from the Promoter. Protect Line is not liable for emails that are lost, undelivered, or not received due to incorrect email addresses, technical issues, or emails being filtered as spam.</li>
<li>This Lifestyle voucher offer cannot be used in conjunction with any other voucher or promotional offer provided by Protect Line. Only one promotional offer can be applied per policy. If multiple offers are available at the time of policy purchase, the customer must choose which single offer to apply.</li>
<li>If a policy is amended or rearranged through Protect Line within the initial six-month qualifying period, the new policy will need to run for a further six months to be eligible for the voucher.</li>
<li>You will not be eligible for the voucher if you miss a payment during the first six months of the policy.</li>
<li>This offer is limited to one Lifestyle voucher per customer, regardless of the number of policies purchased.</li>
<li>The offer is available online and via our phone line, but only when the policy is accepted after originating from an eligible Protect Line Ltd advert, email, or text message.</li>
<li>Lifestyle vouchers can be used at more than 140 of the UK's best-loved retail, cinema, holiday, dining and pub brands. For more details about the brands included, please visit thelifestylevoucher.co.uk.</li>
<li>Vouchers will expire 18 months after being issued, and any outstanding balance will be lost.</li>
<li>No cash alternative will be offered.</li>
<li>The Promoter reserves the right to refuse issuance of the voucher should they suspect foul play.</li>
<li>The Promoter reserves the right to withdraw or amend this offer and these terms and conditions at any time.</li>
<li>The Promoter accepts no responsibility for any damage, loss, liabilities, or disappointment incurred or suffered by you as a result of entering the Promotion or accepting the voucher.</li>
<li>Our Privacy Policy explains how your data will be used.</li>
<li>This promotion is in no way sponsored, endorsed, or administered by, or associated with, Lifestyle Vouchers, Facebook, or X (formerly known as Twitter).</li>
<li>These terms and conditions shall be governed by and construed in accordance with the laws of England and Wales. The Courts of England and Wales shall have exclusive jurisdiction in connection with these terms and conditions.</li>
<li>If any paragraph in these terms and conditions is found by any court or administrative body of competent jurisdiction to be invalid or unenforceable, such invalidity or unenforceability shall not affect the other paragraphs of these terms and conditions, which shall remain in full force and effect.</li>
</ol>
<p>&nbsp;</p>
<p>Updated:01/01/2025</p></div></div>`
    }

    return <StandardPage postInfo={postInfo} post={post} />
}

export default Page

